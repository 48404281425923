import { __assign } from "tslib";
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
export var mfaInitialValue = {
    show: false,
    verified: false,
    type: '',
    contextID: '',
    cb: null,
    cbIsPath: false,
};
var storage = createJSONStorage(function () { return sessionStorage; });
var storedValue = JSON.parse(sessionStorage.getItem('anx_oss_mfv') || 'null');
var mfaValue = __assign({}, mfaInitialValue);
if (storedValue) {
    mfaValue = storedValue;
}
export var mfaAtom = atomWithStorage('anx_oss_mfv', mfaValue, storage);
