import * as React from 'react';
import { useHistory } from 'react-router';
import { useAtom } from 'jotai';
import { mfaAtom, mfaInitialValue } from '../../stores';
import { Constants, getImageByName } from '../../utils';
import SendCode from './SendCode';
import VerifyCode from './VerifyCode';
import { Container, Wrapper } from './style';
var logo = Constants.WHOLESALER === 'OE'
    ? getImageByName('logo-white.png')
    : getImageByName('logo.png');
export default function MfaCode() {
    var history = useHistory();
    var _a = useAtom(mfaAtom), mfa = _a[0], setMfa = _a[1];
    var _b = React.useState(0), step = _b[0], setStep = _b[1];
    var _c = React.useState(''), type = _c[0], setType = _c[1];
    var _d = React.useState(''), contextID = _d[0], setContextID = _d[1];
    React.useEffect(function () {
        if (!mfa.verified) {
            if (mfa.show) {
                setStep(1);
            }
            else {
                setStep(0);
            }
        }
        if (mfa.verified) {
            setStep(0);
        }
    }, [mfa]);
    var onSendSuccess = function (mType, ctxId) {
        setType(mType);
        setContextID(ctxId);
        setStep(2);
    };
    var onClickHome = function () {
        setMfa(mfaInitialValue);
        history.push('/home');
    };
    if (step == 0) {
        return null;
    }
    return (React.createElement(Container, null,
        React.createElement(Wrapper, null,
            step === 1 && React.createElement(SendCode, { logo: logo, onSuccess: onSendSuccess }),
            step === 2 && (React.createElement(VerifyCode, { logo: logo, type: type, contextID: contextID, onBack: function () { return setStep(1); }, onHome: onClickHome })))));
}
