import { __assign } from "tslib";
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import useFetch from '../../utils/useFetch';
import { onLogout } from '../../utils/authAction';
import { mfaAtom, mfaInitialValue, profileAtom } from '../../stores';
import { Button, H5, H6, Input, Paragraph, Spacer } from '../elements';
import { Loading } from '../loading';
import { Modal, ModalMessage } from '../modal';
import TopNav from './TopNav';
export default function VerifyCode(_a) {
    var logo = _a.logo, type = _a.type, contextID = _a.contextID, onBack = _a.onBack, onHome = _a.onHome;
    var _b = useFetch(), state = _b[0], fetch = _b[1];
    var _c = useFetch(), request = _c[0], fetchRequest = _c[1];
    var profile = useAtom(profileAtom)[0];
    var _d = useAtom(mfaAtom), mfa = _d[0], setMfa = _d[1];
    var _e = useState(0), attempts = _e[0], setAttempts = _e[1];
    var _f = useState(false), showMax = _f[0], setShowMax = _f[1];
    var _g = useState(null), verificationCode = _g[0], setVerificationCode = _g[1];
    var _h = useState(''), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = React.useState({
        show: false,
        title: '',
        message: '',
        success: false,
    }), modalState = _j[0], setModalState = _j[1];
    useEffect(function () {
        if (request.success) {
            if (request.data && request.data.success) {
                setModalState({
                    show: true,
                    success: false,
                    title: 'Send Code Success',
                    message: (React.createElement(ModalMessage, { centerMessage: true, message: "Code has successfully been sent. Please check your ".concat(type === 'sms' ? 'SMS' : 'email', "."), hideIcon: true })),
                });
                fetchRequest.reset();
            }
            if (request.data && !request.data.success) {
                setModalState({
                    show: true,
                    success: false,
                    title: 'Send Code Error',
                    message: (React.createElement(ModalMessage, { centerMessage: true, message: state.data.message
                            ? state.data.message
                            : 'There was an error sending the code. Please try again.', hideIcon: true })),
                });
            }
            fetchRequest.reset();
        }
        if (state.error) {
            setModalState({
                show: true,
                success: false,
                title: 'Send Code Error',
                message: (React.createElement(ModalMessage, { centerMessage: true, message: state.data.message
                        ? state.data.message
                        : 'There was an error sending the code. Please try again.', hideIcon: true })),
            });
            fetchRequest.reset();
        }
    }, [request]);
    useEffect(function () {
        if (state.success) {
            if (state.data && state.data.success) {
                if (!mfa.cbIsPath) {
                    mfa.cb();
                }
                setMfa(__assign(__assign({}, mfaInitialValue), { verified: true }));
                fetch.reset();
            }
            if (state.data && !state.data.success) {
                setAttempts(attempts + 1);
                setErrorMessage(state.data.message);
                fetch.reset();
            }
        }
        if (state.error) {
            setAttempts(attempts + 1);
            setModalState({
                show: true,
                success: false,
                title: 'Send Code Error',
                message: (React.createElement(ModalMessage, { centerMessage: true, message: state.data.message
                        ? state.data.message
                        : 'There was an error verifying your code. Please try again.', hideIcon: true })),
            });
            fetch.reset();
        }
    }, [state]);
    var requestMFA = function () {
        var url = type === 'sms'
            ? '/api/v1/mobile/initiate-port-in-verification'
            : '/api/v1/mobile/initiate-email-verification';
        var payload = __assign({ contextId: contextID, accountCode: profile.accountCode }, (type === 'sms'
            ? { portInMsn: profile.contacts[0].phoneMobile }
            : { emailAddress: profile.email }));
        fetchRequest.go(url, 'POST', JSON.stringify(payload), 'application/json');
    };
    var verifyMfaCode = function () {
        if (attempts === 3) {
            setShowMax(true);
            return;
        }
        var url = type === 'sms'
            ? '/api/v1/mobile/validate-port-in-verification'
            : '/api/v1/mobile/handle-email-verification';
        var payload = __assign(__assign({ contextId: contextID }, (type === 'sms'
            ? { portInMsn: profile.contacts[0].phoneMobile }
            : { emailAddress: profile.email })), { verificationCode: verificationCode });
        fetch.go(url, 'POST', JSON.stringify(payload), 'application/json');
    };
    return (React.createElement(React.Fragment, null,
        (request.loading || state.loading) && React.createElement(Loading, { iconWidth: "90px" }),
        React.createElement(TopNav, { logo: logo, onBack: onBack, onClickHome: onHome }),
        React.createElement(H6, null, "Please supply your 8 digit code below to proceed."),
        React.createElement(Spacer, { height: "12px" }),
        React.createElement(Input, { type: "text", block: true, style: { textAlign: 'center' }, onChange: function (e) { return setVerificationCode(e.target.value); } }),
        React.createElement(Spacer, { height: "12px" }),
        errorMessage && React.createElement(Paragraph, null, errorMessage),
        React.createElement(Spacer, { height: "12px" }),
        React.createElement(Button, { disabled: !verificationCode, variant: "danger", style: { width: '150px' }, onClick: function () { return verifyMfaCode(); } }, "Verify Code"),
        React.createElement(Spacer, { height: "6px" }),
        React.createElement(Paragraph, null,
            "Remaining attempts: ",
            3 - attempts),
        React.createElement(Spacer, { height: "12px" }),
        React.createElement(H5, { style: { margin: '0' } }, "Didn't receive your code?"),
        React.createElement(Paragraph, null, "Click try again to send a new code or call us on 1300 980 711"),
        React.createElement(Button, { variant: "danger", style: { width: '150px' }, onClick: requestMFA }, "Try again"),
        React.createElement(Modal, { hideCloseIcon: true, open: modalState.show, title: modalState.title, hideCancel: true, onOk: function () { return setModalState({ show: false, success: false, message: '' }); } }, modalState.message),
        React.createElement(Modal, { hideCloseIcon: true, open: showMax, title: "Verification Failed", hideCancel: true, onOk: function () { return onLogout(); } },
            React.createElement(Paragraph, { style: { textAlign: 'center' } },
                "You have exceeded your maximum tries.",
                React.createElement("br", null),
                "You will be log out."))));
}
